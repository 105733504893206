import ReactTooltip from 'react-tooltip'

import TableHeadCheckbox from './TableHeadCheckbox'
import TableHeadAvatar from './TableHeadAvatar'
import TableHeadCell from './TableHeadCell'
import TableHeadActions from './TableHeadActions'
import TableHeadBorder from './TableHeadBorder'
import TableColumnExpand from './TableHeadExpand'
import s from './TableHead.scss'
import { CheckboxList, CustomPopper, Tooltip } from 'simple-core-ui'
import { BsPlusSquare } from 'react-icons/bs'

const TableHead = ({
  columns,
  avatarHeader,
  hasAvatar,
  hasActions,
  actions = [],
  hasBorder,
  isEmpty,
  sortTable,
  allRowsSelected,
  selectAllRows,
  checkboxSize,
  showBulkSelectAll,
  expandable,
  alwaysShowActions,
  hasCgOutlineCheckbox,
  customizableColumns
}) => (
  <thead className={s.container}>
    <tr>
      {selectAllRows && (
        <TableHeadCheckbox
          isVisible={!isEmpty}
          allRowsSelected={allRowsSelected}
          selectAllRows={selectAllRows}
          checkboxSize={checkboxSize}
          showCheckbox={showBulkSelectAll}
          isCgOutline={hasCgOutlineCheckbox}
        />
      )}
      {expandable && <TableColumnExpand />}
      {hasAvatar && <TableHeadAvatar avatarHeader={avatarHeader} />}
      {columns.map(({ columnKey, content, style = {}, isSortable, isDesc, required }, idx) => (
        <TableHeadCell
          content={content}
          sortColumn={() => {
            if (isSortable) {
              ReactTooltip.hide()
              sortTable({ columnKey, isDesc: !isDesc })
            }
          }}
          style={{ cursor: isSortable ? 'pointer' : 'default', ...style }}
          isSortable={isSortable}
          columnKey={columnKey}
          isDesc={isDesc}
          required={required}
          key={idx}
        />
      ))}
      {(hasActions || !!actions.length) && (
        <TableHeadActions alwaysShowActions={alwaysShowActions} />
      )}
      {customizableColumns && (
        <th className={s.customizeColumns}>
          <CustomPopper
            component={
              <Tooltip
                trigger={<BsPlusSquare style={{ fontSize: '23px', cursor: 'pointer' }} />}
                content="Add Columns"
                placement="top"
              />
            }
          >
            <CheckboxList
              value={customizableColumns.visibleColumns ?? columns}
              onConfirm={customizableColumns.changeSelectedColumns}
              sortSelection={customizableColumns.sortSelection}
              options={customizableColumns.allColumns}
              serialiser={data => data}
              secondaryAction={{
                element: (
                  <a
                    style={{
                      marginLeft: 10
                    }}
                    className="makeLink"
                  >
                    Restore Default
                  </a>
                ),
                onClick: customizableColumns.restoreDefaultColumns
              }}
              searchPlaceholder="Search column names"
            />
          </CustomPopper>
        </th>
      )}
      {hasBorder && <TableHeadBorder />}
    </tr>
  </thead>
)

export default TableHead
